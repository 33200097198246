import React from "react";

// reactstrap components
import { Card } from "react-bootstrap";

function Course(props) {
    return (
      <>
        <Card className="card-tasks">
          
          <Card.Header><h3>{props.CourseName}</h3></Card.Header>
          <Card.Body>
          {props.CourseInstructor}
          </Card.Body>
        </Card>
      </>
    );
  }
  
  export default Course;